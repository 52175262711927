export default [
  "Astronomy",
  "Biology",
  "Botany",
  "Geology",
  "History",
  "Language",
  "Math",
  "Medicine",
  "Miscellaneous",
  "Mythology",
  "Science",
  "Zoology",
].sort()
