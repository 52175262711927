import React from "react"

import Layout from "../components/layout"
import Library from "../components/library"
import SEO from "../components/seo"

export default function LibraryPage() {
  return (
    <Layout noStyles>
      <SEO title="Library" />

      <Library />
    </Layout>
  )
}
