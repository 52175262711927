import React from "react"
import sortBy from "lodash/sortBy"
import { Modal, ModalBody, ModalFooter } from "reactstrap"
import { useSelector } from "react-redux"
import { navigate } from "gatsby"

import MainConceptData from "./mainConceptData"
import categories from "../../lib/categories"
import { Concepts_concepts, Concepts_concepts_root_appearances } from "../../hasura/queries/types/Concepts"
import { User_users_by_pk_experience_concept_root_appearances } from "../../hasura/queries/types/User"
import { caseInsEq, imageFor, sharesRootWith } from "../../lib/helpers"
import { conceptSelector, ConceptState } from "../../hasura/slices/concept"
import { UserState, userSelector } from "../../hasura/slices/user"

export type RootAppearance = User_users_by_pk_experience_concept_root_appearances | Concepts_concepts_root_appearances

interface Props {
  concept: Concepts_concepts
  setDisplayConceptId: (displayConceptId?: number) => void
}

export default function ConceptModal(props: Props) {
  const { concept } = props

  const { concepts }: ConceptState = useSelector(conceptSelector)
  const { isAdmin, hasPremium }: UserState = useSelector(userSelector)

  const categoriesJoined = (concept.categories_denormalized || "")
    .split(",")
    .filter((c) => categories.some((c2) => caseInsEq(c, c2)))
    .sort()
    .join(", ")

  const relatedTo = sortBy(
    (concepts || []).filter((c) => sharesRootWith(c, concept)),
    "display_name"
  )

  const isLocked = !isAdmin && !props.concept.is_basic && !hasPremium

  return (
    <Modal fade={false} centered isOpen size="lg" toggle={() => props.setDisplayConceptId(undefined)}>
      <ModalBody className="px-5 py-4 min-height-400px d-flex flex-column justify-content-between">
        <div className="d-flex align-items-start justify-content-between">
          <MainConceptData concept={concept} />

          <div className="text-right min-width-200px">
            {categoriesJoined.length > 0 && (
              <p className="m-0 text-xs text--gray6">
                <span className="">{categoriesJoined}</span>
              </p>
            )}

            <p className="m-0 text-xs text--gray6">difficulty {concept.obscurity}</p>
          </div>
        </div>

        <div style={{ overflowX: "scroll", gap: "20px" }} className="my-4 d-flex">
          {concept.concept_image_appearances.map((c, idx) => (
            <img key={idx} className="max-height-250px w-auto h-auto rounded" src={imageFor(c.image.s3_path)} />
          ))}
        </div>

        <p className="text-s m-0">
          <span className="bold mr-2 text--gray8">related to</span>

          {relatedTo
            .map((c, idx) => (
              <span
                key={idx}
                onClick={() => props.setDisplayConceptId(c.id)}
                className={`${c.is_basic ? "" : "text--purple"} pointer hover-underline`}
              >
                {c.display_name}
              </span>
            ))
            .reduce((prev: any, curr: any, idx: number) => (prev.length ? [prev, <span key={`separator-${idx}`}>, </span>, curr] : [curr]), [])}
        </p>
      </ModalBody>

      {isLocked && (
        <ModalFooter>
          Unlock {concept.display_name} and thousands of other words with{" "}
          <span className="bold text--purple pointer" onClick={() => navigate("/premium")}>
            Premium
          </span>
        </ModalFooter>
      )}
    </Modal>
  )
}
